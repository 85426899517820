
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient } from '@/services/Services';
import ExperienceResume from '../components/experience/experienceResume.vue';
import viewDescriptionModal from '../modals/viewDescriptionModal.vue';
import editMyApplicationModal from '../modals/experience/editMyApplicationModal.vue';
import * as OM from '@/Model';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
        ExperienceResume
    }
})
export default class ExperienceApplicationDetail extends Vue {

    affinityDetail: OM.MyApplicationExperienceAffinityDetailVM = new OM.MyApplicationExperienceAffinityDetailVM();
    affinityIdentifier: string = "";

    created() {
        this.affinityIdentifier = this.$route.params.affinityIdentifier.toString();
        this.init();
    }

    init(){
        ExperienceAffinityClient.getMyApplicationDetailByAffinityId(this.affinityIdentifier)
        .then(x => {
            this.affinityDetail = x;
        })
    }

    get affinityRateText(){
        return this.$localizationService.getLocalizedValue("app_AffinityRate{{rate}}", "Affinity Rate: {{rate}}%", "", "", { rate: this.affinityDetail.match.affinityPercentage });   
    }

    get canRemoveApplication(){
        return !this.affinityDetail.match.chatRoomIdentifier && !this.affinityDetail.experience.isExpired && 
            !this.affinityDetail.application.acceptedOn && !this.affinityDetail.application.refusedOn;
    }

    openDescription() {
        if(!this.affinityDetail.match.biography)
            return;
            
        this.$opModal.show(viewDescriptionModal, {
            ownDescription: false,
            completeName: this.affinityDetail.match.personName,
            birthData: this.affinityDetail.match.birthDate,
            meters: this.affinityDetail.match.distanceMeters,
            sexualOrientation: this.affinityDetail.match.sexualOrientation,
            description: this.affinityDetail.match.biography
        })
    }
    
    editApplication(){
        this.$opModal.show(editMyApplicationModal, {
            name: this.affinityDetail.match.personName,
            callback: () => {
                this.$opModal.closeLast();
                this.cancelMyApplication();
            }
        })
    }

    cancelMyApplication(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_DesideriDavveroRimuovereLaTuaCandidatura?", "Desideri davvero rimuovere la tua candidatura?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_Annulla", "Annulla"),
            confirmCb: () => {
                ExperienceAffinityClient.removeMyApplicationFromExperience(this.affinityDetail.match.matchIdentifier, this.affinityDetail.experience.experienceIdentifier)
                .then( x => {
                    this.$opModal.closeLast();
                    this.$opModal.show(InfoModal, {
                        text: this.$localizationService.getLocalizedValue("app_Successo", "Successo"),
                        subtitle: this.$localizationService.getLocalizedValue("app_CandidaturaRimossaConSuccesso!", "Candidatura rimossa con successo!"),
                        confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        }
                    }, () => {
                        this.$router.replace("/my-application-list/");
                    })
                })
                .catch( ex => {
                    this.$opModal.show(InfoModal, {
                        text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                        subtitle: ex.Message,
                        deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        denyCb: () => {
                            this.$opModal.closeLast();
                        }
                    })
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    goToChat(chatRoomIdentifier: string){
        this.$router.push("/chats/" + chatRoomIdentifier);
    }

}
